<template>
  <div id="panelNav" class="flex flex-row items-center lg:flex-col">
    <div
      v-for="(nav, i) in tabListFiltered"
      :key="i"
      class="flex w-full items-center text-base"
    >
      <nav-button
        v-if="tab"
        v-tooltip.left="{ content: nav.tooltip, classes: 'tooltip-hidden' }"
        :class="{
          'opacity-50': editComment,
          'tab-link-active': tab.title.includes(nav.title),
        }"
        color="dark"
        class="relative flex-1 py-3"
        :disabled="editComment || appointmentLoading || !appointment.id"
        @click="$emit('select-tab', nav)"
      >
        <div class="flex items-center">
          <fv-icon v-if="nav.icon" :icon="nav.icon" size="md" />
          <span
            v-if="showCountValue(nav.countValue)"
            class="absolute top-0 right-0 mr-4 mt-1 h-4 w-4 rounded-full bg-primary text-xs font-semibold text-white"
          >
            {{
              (appointment[nav.countValue] &&
                appointment[nav.countValue].length) ||
              countValue
            }}
          </span>
        </div>
      </nav-button>
    </div>
  </div>
</template>

<script>
import roomTabs from '@/config/room-tabs';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      countValue: null,
    };
  },
  computed: {
    ...mapState(['platform']),
    ...mapGetters({
      tab: 'getTab',
    }),
    ...mapGetters('journal', {
      isFinished: 'getIsFinished',
      editComment: 'getEditComment',
    }),
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('animal', {
      animalId: 'getAnimalId',
    }),
    ...mapGetters('appointment', {
      appointmentLoading: 'getLoading',
      appointment: 'getAppointment',
    }),

    tabListFiltered() {
      return roomTabs
        .map(tab => {
          if (this.isFinished) {
            if (tab.title === 'Journal') {
              return {
                ...tab,
                icon: 'consultations-completed',
                titleIcon: 'check',
                tooltip: 'Read the journal',
              };
            }
          }
          return tab;
        })
        .filter(tab => {
          if (!this.isAdmin) {
            if (tab.auth.admin) {
              return false;
            }
            return tab;
          }
          if (tab.platform.length && this.platform !== tab.platform) {
            return false;
          }
          return tab;
        });
    },
  },

  watch: {
    animalId() {
      if (this.animalId) {
        this.getHistory();
      }
    },
  },
  mounted() {
    if (this.animalId) {
      this.getHistory();
    }
  },
  methods: {
    ...mapActions('animal', ['fetchHistory']),
    showCountValue(countValue) {
      return (
        (countValue && this.appointment[countValue]?.length) ||
        (countValue && this.countValue && !this.appointment[countValue])
      );
    },
    async getHistory() {
      const { data } = await this.fetchHistory(this.animalId);
      this.countValue = null;
      const finishedAppointments = data.appointments
        .filter(a => a.booking_id)
        .filter(a => a.id !== this.appointment.id);

      this.countValue = finishedAppointments.length + data.questions.length;
    },
  },
};
</script>
