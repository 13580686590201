<template>
  <div>
    <div class="leading-normal">
      <div class="flex flex-col">
        <booking-information
          :appointment="appointment"
          :booking-meta-data="bookingMetaData"
          :images="appointment.images"
          :animal="animal"
          :is-editing-enabled="true"
          class="mb-10"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BookingInformation from '@/components/booking/BookingInformation';

export default {
  components: {
    BookingInformation,
  },
  computed: {
    ...mapGetters('appointment', {
      appointment: 'getAppointment',
    }),
    ...mapGetters('animal', {
      animal: 'getAnimal',
      animalGender: 'getGenderName',
    }),
    ...mapState('appointment', ['bookingMetaData']),
  },
};
</script>
