var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "leading-normal" }, [
      _c(
        "div",
        { staticClass: "flex flex-col" },
        [
          _c("booking-information", {
            staticClass: "mb-10",
            attrs: {
              appointment: _vm.appointment,
              "booking-meta-data": _vm.bookingMetaData,
              images: _vm.appointment.images,
              animal: _vm.animal,
              "is-editing-enabled": true,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }